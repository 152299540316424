import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layoutPhoenix"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Connect" />
    <h1>Hi people</h1>
    <p>This is a basic layout.</p>
    <p>Let's look at the rest of the site.</p>
  </Layout>
)

export default IndexPage
